import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="container">
        <title>Не е намерено</title>
        <h1>Грешка 404</h1>
        <p>Страницата не съществува.</p>
        <Link to="/" className="text-secondary">Обратно на началната страница</Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
